
import fnApi from '/utils/common/methods'

export default {
    name: 'Quick',
    props: {
        option: Object,
        visible: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    components: {},
    asyncData({api, cookies, ...context}) {},
    data() {
        return {
            show: this.visible, // 侧导航是否显示
            position: 'left',
            showType: 0,        // 显示选类别 1 国家 2 货币 3 语言
            closeShow: true,   // 关闭展示
            emptyShow: false,   // 空数据
            border: false,
            categoryData: [],   // 分类产品数据
            tabObj:{            // nav导航标签数据
                show: true,
                tab: 0,
                title: ''
            },
            singleObj:{         // 单个标签数据
                show: false,
                title: '',
                list: []
            },
            categoryObj:{       // 分类数据
                showMenu: true,
                showCell: false,
                list: [],
                id: '',
                type: 3,        // 分组类型 3 三级 4 四级
                banner: {       // 分类广告
                    src: [],
                    visible: false
                }
            },
            country: {          // 国家列表
                visible: false, // 是否显示国家列表
                countryCode: '',// 国家code
                value: '',      // 国家
                list: []
            },
            language: {         // 语言
                visible: false,
                list: '',
                identify: ''
            },
            currency: {         // 货币
                visible: false,
                list: '',
                code: ''
            },
            payIcon: {          // 支付图标
                list: []
            },
            agreement: [],      // 协议
            media: [],          // 媒体
            third: []           // 三方
        }
    },
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getCategoryStyleAjax()
        this.getPayIconAjax()
    },
    methods: {
        // 接口
        getCategoryAjax() { // 获取分组列表
            this.$api.category.getMenu().then(response => {
                const res = response.result || [];
                this.categoryData = res || []
                this.categoryObj.id = this.categoryData[0]?.id
                this.setCategoryData()
                this.categoryFindSet()
            })
        },
        setCategoryData() {
            const language = this.$storage.get('language');
            this.categoryData.forEach(p => {
                const menuBubble = p.menuBubble || {};
                p.hasChild = !!p.children?.length
                p.style = {
                    color: p.menuColor?.showColor
                }
                p.menuBubbleIcon = menuBubble[language.identify] || ''
            })
        },
        getCategoryStyleAjax() { // 获取分类展示类型接口
            this.$api.category.getCategoryStyle().then(response => {
                const res = response.result || 3;
                this.categoryObj.type = res
                this.getCategoryAjax()
            })
        },
        // 获取广告接口
        getAdAjax() {
            this.$api.common.getAd({
                places: 4,
                catPathId: this.categoryObj.id
            }).then(response => {
                let res = response.result || [];
                this.categoryObj.banner.src = res || []
                const banner = this.categoryObj.banner.src;
                this.categoryObj.banner.length = banner.length
                this.categoryObj.banner.visible = !!banner.length
                this.categoryObj.banner.hasPage = banner.length > 1
            })
        },
        getCountryAjax() { // 获取国家列表
            this.$api.common.getCountryList().then(response => {
                const res = response.result || [];
                this.country.list = res
            })
        },
        getLanguageAjax() { // 获取语言列表
            this.$api.common.getLang().then(response => {
                const res = response.result || [];
                this.language.list = res
            })
        },
        getCurrencyAjax() {
            this.$api.common.getCurrency().then(response => {
                const res = response.result || [];
                this.currency.list = res
            })
        },
        getPayIconAjax() {
            this.$api.common.getCountryPayIcon({
                countryCode: this.country.countryCode
            }).then(response => {
                const res = response?.result?.result || {};
                const mSupportPaymentIcon = res.mSupportPaymentIcon ? res.mSupportPaymentIcon.split(',') : [];
                this.payIcon.list = []
                mSupportPaymentIcon.forEach(p => {
                    this.payIcon.list.push({
                        pic: p
                    })
                })
                this.payIcon.list = [
                    ...this.payIcon.list,
                    ...this.third
                ]
            })
        },
        closeClick() { // 关闭按钮
            this.option.visible = false
            this.closeShow = false
        },
        tagClick() {
            this.option.visible = true
            this.closeShow = true
        },
        popupCloseEvent() { // 弹出层关闭事件
            this.closeShow = false
            this.$emit('update:visible', false)
        },
        tabClick(e, obj) { // 导航 tab 点击
            if(!obj.hasChild) {
                window.location.href = obj.jumpUrl
                return
            }
            this.categoryObj.id = this.categoryData[e].id
            // this.categoryFindSet()
            // 新
            this.categoryObj.list = this.categoryData[e].children || []
            // this.emptyShow = !category.children?.length
            this.emptyShow = !this.categoryObj.list.length
            this.singleObj.title = this.categoryData[e].menuName
            this.tabObj.title = this.singleObj.title
            this.singleObj.show = true
            this.tabObj.show = false
            this.categoryObj.showMenu = false
            this.categoryObj.showCell = true
            // 广告位
            const menuIconArr = obj.menuIcon ? [{
                ...obj,
                pic: obj.menuIcon
            }] : [];
            const menuIconLength = menuIconArr.length;
            this.categoryObj.banner.src = menuIconArr
            this.categoryObj.banner.length = menuIconLength
            this.categoryObj.banner.visible = !!menuIconLength
            this.categoryObj.banner.hasPage = menuIconLength > 1
            // this.getAdAjax()
        },
        navBack(obj) { // 导航返回
            this.singleObj.show = false
            this.tabObj.show = true
            this.categoryObj.showMenu = true
            this.categoryObj.showCell = false
            this.showType = 0
            this.emptyShow = !this.categoryObj.list.length
            this.tabObj.title = this.singleObj.title
            this.$VueScrollTo.scrollTo('.quick-title', '', {
                container: '.quickBox__section',
                duration: 0
            })
        },
        categoryClick(obj) { // 分类点击
            this.singleObj.show = true
            this.tabObj.show = false
            this.categoryObj.showMenu = false
            this.categoryObj.showCell = true
            this.singleObj.title = obj.categoryName
            this.tabObj.title = this.singleObj.title
            this.singleObj.list = obj.children
            this.emptyShow = !this.singleObj.list.length
        },
        categoryFindSet() { // 分类数据查询
            let category = fnApi.common.levelDataFind({
                list: this.categoryData,
                target: [this.categoryObj.id],
                key: 'id',
                childKey: 'children'
            })
            this.categoryObj.list = category.children
            // this.emptyShow = !category.children?.length
            // this.emptyShow = !(category.children && category.children.length)
        },
        goLinkCell(obj,type) { // 分类类目跳转产品详情
            let url = '';
            if(type=='all') {
                url = obj.children[0].jumpUrl
            } else {
                url = obj.jumpUrl
            }
            // url && (window.location.href = url)
            if(url){
                this.option.visible = false
                window.location.href = url
            }
            // if(type=='all') {
            //     this.$router.push(`/category/${obj.children[0].categoryCode}/${obj.seoUrl}?type=1&children=1`)
            // } else if(obj.seoUrl) {
            //     this.$router.push(`/category/${obj.categoryCode}/${obj.seoUrl}?type=1`)
            // } else {
            //     this.$router.push({
            //         name: 'Search',
            //         query: {
            //             keyword: obj.id
            //         }
            //     })
            // }
        },
        handleShowCountry() { // 国家
            this.showType = 1
            this.singleObj.show = true
            this.tabObj.title = this.$translate('Select Country')
            !this.country.list.length && this.getCountryAjax()
        },
        handleCountry(obj) { // 选择国家
            const origin = location.origin;
            const data = obj
            const mRealmName = obj.mRealmName;
            const hasLocalHost = origin.includes('.10.10')
            this.country.value = obj.id
            this.option.visible = false
            if(mRealmName == origin || hasLocalHost) {
                // 同域名
                this.$storage.set('country', data, true)
                this.$storage.set('currency', {
                    code: data.currencyCode,
                    symbol: data.currencySymbol
                }, true)
                this.$storage.remove('order/placeAddress')
                
                this.setReload()
            } else {
                // 非同域名
                const route = this.$route;
                const deviceId = this.$storage.get('deviceId');
                const token = this.$storage.get('token');
                const userId = this.$storage.get('userId');
                // link参数
                let query = route.query;
                deviceId && (query.deviceId = deviceId)
                token && (query.token = token)
                userId && (query.userId = userId)
                delete query.country_code
                // 删除utm信息
                delete query.utm_source
                delete query.utm_medium
                delete query.utm_campaign
                delete query.utm_content
                delete query.utm_id
                delete query.gad_source
                delete query.gclid
                delete query.utm_term
                delete query.utm_awc
                delete query.utm_sn

                mRealmName.includes('m.yfn.com') && (query.country_code = obj.countryCode)
                // link拼接
                let queryArr = [];
                Object.keys(query).forEach(key => {
                    queryArr.push(`${key}=${query[key]}`)
                })
                const queryAttr = queryArr.join('&');
                const symbol = queryAttr ? '?' : '';
                const href = `${mRealmName}${route.path}${symbol}${queryAttr}`;
                window.location.href = href
            }
        },
        handleAd(obj) {
            obj.jumpUrl && (window.location.href = obj.jumpUrl)
        },
        handleShowLanguage() { // 语言
            this.showType = 3
            this.singleObj.show = true
            this.tabObj.title = this.$translate('Select Language')
            !this.language.list.length && this.getLanguageAjax()
        },
        handleLanguage(obj) {
            this.$api.common.getCountryList({language:obj.identify}).then(response => {
                const res = response.result || [];
                let country = res.find((item)=>{return item.countryCode == this.country.countryCode})
                this.$storage.set('country' , country);
                this.$cookies.set('country', country);
                this.language.identify = obj.identify
                this.$storage.set('language', obj, true)
                this.option.visible = false
                this.setReload()
            })
        },
        handleShowCurrency() { // 货币
            this.showType = 2
            this.singleObj.show = true
            this.tabObj.title = this.$translate('Select Currency')
            !this.currency.list.length && this.getCurrencyAjax()
        },
        handleCurrency(obj) {
            this.currency.code = obj.code
            const data = {
                code: obj.code,
                symbol: obj.symbol
            }
            this.$storage.set('currency', data, true)
            this.option.visible = false
            this.setReload()
        },
        setReload() { // 刷新当前页面
            this.$loading()
            setTimeout(() => {
                this.$loading(false)
                // this.$router.go(0)
                let currentUrl = window.location.href;
                let url = new URL(currentUrl);
                url.searchParams.delete('country_code');
                window.location.href = url.toString();
            }, 1000)
        },
        goTrack() { // 跳转物流查询页
            this.option.visible = false
            this.$router.push({
                name: 'TrackSearch'
            })
        },
        jumpContact() { // 跳转客服页
            this.option.visible = false
            this.$router.push({
                name: 'ContactUs'
            })
        },
        goLinkAgreement(obj) { // 协议跳转
            this.option.visible = false
            if(obj.path) {
                this.$router.push(obj.path)
            } else {
                window.location.href = obj.href
            }
        },
        initialSet() { // 初始化设置
            const languageStore = this.$store.state.national.language
            const language = this.$storage.get('language') || languageStore || {};
            const eastCountryCode = ['YE', 'JO', 'QA', 'BH', 'OM', 'SA', 'AE', 'KW'];
            const countryStore = this.$store.state.national.country
            const country = this.$storage.get('country') || countryStore || {};
            const currency = this.$storage.get('currency') || {};
            this.country.value = country.id
            const query = this.$route.query;
            this.country.countryCode = query.country_code|| country.countryCode
            this.language.identify = language.identify
            this.language.language = language.language
            this.currency.code = currency.code
            if(['ar_SA'].includes(this.language.identify)) {
                this.position = 'right'
            }
            this.media = [{
                    url: 'https://www.facebook.com/YFNCOM',
                    src: '/assets/icon/icon_1.png'
                }, {
                    url: 'https://instagram.com/officialyfnjewelry',
                    src: '/assets/icon/icon_2.png'
                }, {
                    url: 'https://www.youtube.com/channel/UCikbSP-wQtjFQJ1U2yzrDbQ',
                    src: '/assets/icon/icon_4.png'
                }, {
                    url: 'https://www.tiktok.com/@yfn.com',
                    src: '/assets/icon/icon_5.png'
                }
                ]
            this.agreement = [{
                title: this.$translate('Shipping Policy'),
                path: '/policy?id=1679369506448H4KdPCGQ'
            }, {
                title: this.$translate('Return Policy'),
                path: '/policy?id=1679369573013aP5MjpvZ'
            }, {
                title: this.$translate('Terms & Conditions'),
                path: '/policy?id=167936955283278Vtn7Mz'
            }, {
                title: this.$translate('Payment Method'),
                path: '/policy?id=1679369593929FHpv8nbx'
            }, {
                title: this.$translate('Privacy Policy'),
                path: '/policy?id=16793696198020vLSBIcB'
            }, {
                title: this.$translate('About Us'),
                path: '/about-us'
            }, {
                title: this.$translate('YFN Blog'),
                href: 'https://www.yfn.com/blog/'
            }, {
                title: this.$translate('Sustainability'),
                href: '/sustainability'
            }, 
            // {
            //     title: this.$translate('Site Map'),
            //     path: '/sitemap'
            // }, 
            {
                title: this.$translate('E-Gift Card'),
                path: '/e-gift-card'
            }]
            this.third = [{
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107956460.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107963431.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107971691.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107982376.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107989067.png'
            }, {
                pic: 'https://cdn2.selleroa.com/yfn-upload/review/1697107991820.png',
            }]
        }
    }
}
